<template>
  <div class="flex flex-1 flex-col">
    <sub-header
      buttonText="Create Order"
      :showBtn="true"
      :handleClick="createOrder"
    ></sub-header>
    <common-detail-table
      :columns-props="columns"
      show-row-link
    ></common-detail-table>
  </div>
</template>

<script>
import CommonDetailTable from "./common-detail-table.vue";
import SubHeader from "@/components/SubHeader";

export default {
  name: "orderList",
  components: {
    CommonDetailTable,
    SubHeader,
  },
  data() {
    return {
      columns: [
        {
          label: "Client Name",
          field: "client_name",
          config: {
            sortable: true,
            filter: false,
            type: "link",
            alignCenter: true,
          },
        },
        {
          label: "Order Id",
          field: "order_number",
          config: {
            filter: false,
            alignCenter: true,
          },
        },
        {
          label: "Credit Pack Names",
          field: "credit_pack_names",
          config: {
            filter: false,
            alignCenter: true,
          },
        },
        {
          label: "Order Ref",
          field: "order_reference",
          config: {
            filter: false,
            alignCenter: true,
          },
        },
        {
          label: "Order Date ",
          field: "created_at",
          field_type: "date",
          sortable: true,
          config: {
            filter: false,
            filter_type: "date",
            type: "showDate",
            query_keys: {
              start: "sale_date_from",
              end: "sale_date_to",
            },
            customClass: "flex justify-center items-center",
          }
        },
        {
          label: "Amount",
          field: "total_amount",
          config: {
            filter: false,
            alignCenter: true,
          },
        },
        {
          label: "Payment",
          field: "payment_status",
          sortable: false,
          config: {
            filter: false,
            type: 'payment_status',
            customClass: "flex items-center justify-center",
          },
        },
        {
          label: "Status",
          field: "status",
          sortable: true,
          config: {
            filter: false,
            type: "status_text",
          },
          tdClass: "truncate max-w-xs",
        },
      ],
    };
  },

  methods: {
    createOrder() {
      this.$router.push({ name: "PurchaseOrderDetail" });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@shared/assets/mixins/mixins.scss";
@include tableComponent;
</style>
