var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"field-table flex-1 flex flex-col max-h-full max-w-full min-h-full text-base-content"},[_c('div',{staticClass:"flex flex-1 flex-col flex-grow max-h-full max-w-full overflow-auto scroll-bar relative"},[_c('table-large',{attrs:{"columns":_vm.columnsProps,"rows":_vm.rows,"total-rows":_vm.totalRows,"isLoading":_vm.loading,"pagination-options":_vm.paginationOptions,"filters":_vm.serverParams,"sort-options":{
        enabled: true,
        dropdownAllowAll: false
      },"sortingEnabled":true,"filterOptions":_vm.columnFilterOptions,"filterOptionsAsync":_vm.columnFilterOptionsAsync,"config":{ sortingOption: { enabled: false } }},on:{"page-change":function($event){return _vm.onPageChange($event)},"per-page-change":function($event){return _vm.onPageChange($event, true)},"sort-change":_vm.onSortChanged,"column-filter":_vm.onColumnFilter,"filters-clear":_vm.clearFilters,"search-filter":function($event){return _vm.getSearchOptionsTick($event)}},scopedSlots:_vm._u([(_vm.showRowLink)?{key:"link",fn:function({ customData }){return [_c('router-link',{staticClass:"text-primary whitespace-nowrap cursor-pointer hover:text-dvbrandhoveron",attrs:{"to":{
            name: 'PurchaseOrderDetail',
            params: {
              id: customData.id,
              client_name: customData.client_name,
            },
          }}},[_vm._v(_vm._s(customData.client_name))])]}}:null,{key:"payment_status",fn:function({ customData }){return [(customData.payment_status === 'paid')?_c('div',{staticClass:"flex items-center text-green-500"},[_c('font-awesome-icon',{attrs:{"icon":"check"}})],1):_vm._e()]}},{key:"status_text",fn:function({ customData }){return [_c('div',{staticClass:"flex justify-center items-center"},[_c('span',{staticClass:"capitalize",class:_vm.getStatusColor(customData.status)},[_vm._v(" "+_vm._s(customData.status))])])]}}],null,true)})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }