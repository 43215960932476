<template>
  <div
    class="field-table flex-1 flex flex-col max-h-full max-w-full min-h-full text-base-content"
  >
    <div
      class="flex flex-1 flex-col flex-grow max-h-full max-w-full overflow-auto scroll-bar relative"
    >
      <!-- @page-change="onPageChange($event)" -->
      <!-- @per-page-change="onPageChange($event, true)" -->
      <table-large
        :columns="columnsProps"
        :rows="rows"
        :total-rows="totalRows"
        @page-change="onPageChange($event)"
        @per-page-change="onPageChange($event, true)"
        :isLoading="loading"
        :pagination-options="paginationOptions"
        :filters="serverParams"
        :sort-options="{
          enabled: true,
          dropdownAllowAll: false
        }"
        :sortingEnabled="true"
        @sort-change="onSortChanged"
        :filterOptions="columnFilterOptions"
        :filterOptionsAsync="columnFilterOptionsAsync"
        @column-filter="onColumnFilter"
        @filters-clear="clearFilters"
        @search-filter="getSearchOptionsTick($event)"
        :config="{ sortingOption: { enabled: false } }"
      >
        <template #link="{ customData }" v-if="showRowLink">
          <router-link
            :to="{
              name: 'PurchaseOrderDetail',
              params: {
                id: customData.id,
                client_name: customData.client_name,
              },
            }"
            class="text-primary whitespace-nowrap cursor-pointer hover:text-dvbrandhoveron"
            >{{ customData.client_name }}</router-link
          >
        </template>
        <template #payment_status="{ customData }">
          <div
            v-if="customData.payment_status === 'paid'"
            class="flex items-center text-green-500"
          >
            <font-awesome-icon icon="check" />
          </div>
        </template>

        <template #status_text="{ customData }">
          <div class="flex justify-center items-center">
            <span :class="getStatusColor(customData.status)" class="capitalize">
              {{ customData.status }}</span
            >
          </div>
        </template>
      </table-large>
    </div>
  </div>
</template>

<script>
import TableLarge from "@shared/dashboard-components/table-large";
import { onlyUnique } from "@/plugins/utils.js";
import { uuid } from "vue-uuid";

import axios from "@/axios";

export default {
  name: "inventory",

  components: {
    // SubHeader,
    TableLarge,
  },

  props: {
    bredCrumbs: {
      required: false,
      type: Array,
      default: () => [],
    },
    columnsProps: {
      required: true,
      type: Array,
      default: () => [],
    },
    showRowLink: {
      required: false,
      type: Boolean,
      default: false,
    },
    creditPackId: {
      required: false,
      type: String,
      default: "",
    },
    dataFetchingUrl: {
      required: false,
      type: String,
      default: "",
    },
    showBackButton: {
      required: false,
      type: Boolean,
      default: false,
    },
    backRedirectionUrl: {
      required: false,
      type: String,
      default: "",
    },
  },

  data: () => {
    return {
      rows: [],
      totalRows: 0,
      paginationOptions: {
        enabled: true,
        mode: "records",
      },
      serverParams: null,
      columnFilterOptionsAsync: {},
      params: {
        page: 1,
        limit: 10,
      },
      loading: false,
      searchLoading: {},
      sortParams: {
        sort: "",
      },
    };
  },

  async mounted() {
    await this.fetchData();
  },

  computed: {
    columnFilterOptions() {
      return this.getFilterOptions(this.columnOption, this.rows);
    },

    columnOption() {
      return this.columnsProps.map((el) => ({
        ...el,
        config: {
          ...el.config,
          isLoading: !!this.searchLoading[el.field] || false,
        },
      }));
    },
  },

  methods: {
    getStatusColor(status) {
      let color ='';
      if (status === "pending approval") {
        color = "text-yellow-500"
           }
           if (status === "rejected") {
            color = "text-red-500"
           }
           if (status === 'draft') {
            color = "text-blue-500"
           }
           if (status === 'approved') {
            color = 'text-green-400'
           }
           return color;
    },
    async fetchData() {
      this.loading = true;
      try {
        let url = `client-purchase-order?${this.sortParams.sort}&page=${this.params.page}&limit=${this.params.limit}`;
        const { data } = await axios.get(url);
        this.rows = data.data ?? [];
        this.rows = this.rows?.map(el => {
          el.credit_pack_names = el.credit_pack_names?.join(', ')
          return el
        })
        this.totalRows = data?.page?.total || this.rows.length;
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },

    searchFilter(column, event) {
      if (this.searchTimeout) clearTimeout(this.searchTimeout);
      const delay = 500;
      this.searchTimeout = setTimeout(() => {
        this.getSearchOptions(column, event);
      }, delay);
    },
   
    clearFilters() {
      this.selectedDropDownItems = null;
      this.getList();
    },
    onClick(id) {
      this.$router.push({
        name: "creditPackInventoryDetail",
        params: { id: id },
      });
    },
    async onSortChanged(data) {
      if (data && data.length && data[0].type !== "none") {
        data = data[0];
        this.sortParams = {
          sort: "sort_on=" + data.field + "&order_by=" + data.type,
        };
        await this.fetchData();
      } else {
        this.sortParams = null;
        await this.fetchData();
      }
    },
    async getSearchOptions(payload) {
      if (!payload.value) this.columnFilterOptionsAsync = null;
      else {
        this.$set(this.searchLoading, payload.column.field, true);
        try {
          const requestData = {
            ...this.serverParams,
            [payload.column.query_key || payload.column.field]: payload.value,
          };
          const { data } = await axios.get(this.url, {
            params: requestData,
          });
          this.columnFilterOptionsAsync = this.getFilterOptions(
            this.columnsProps,
            data?.data
          );
        } catch (error) {
          console.error("getSearchOptions :>> ", error);
        }
        this.$set(this.searchLoading, payload.column.field, false);
      }
    },
    async onPageChange(data, resetPage = false) {
      if (resetPage) {
        this.params.page = 1;
      } else this.params.page = data.currentPage;
      this.params.limit = data.currentPerPage;
      await this.fetchData();
    },
    // async clearFilters() {
    //   this.serverParams = null;
    //   await this.fetchData();
    // },
    getSearchOptionsTick(payload) {
      if (this.searchTimeout) clearTimeout(this.searchTimeout);
      const delay = 500;
      this.searchTimeout = setTimeout(() => {
        this.getSearchOptions(payload);
      }, delay);
    },
    async onColumnFilter({ columnFilters }) {
      this.params.page = 1;
      this.serverParams = columnFilters ? { ...columnFilters } : null;
      await this.fetchData();
    },
    getFilterOptions(columns, rows) {
      return columns.reduce((result, curr) => {
        result[curr.field] = rows
          .filter((row) => row[curr.field])
          .map((row) => row[curr.field])
          .filter(onlyUnique)
          .map((el) => ({ id: uuid.v4(), name: el }));
        return result;
      }, {});
    },
  },
};
</script>

<style scoped lang="scss">
@import "@shared/assets/mixins/mixins.scss";
@include tableComponent;
</style>
